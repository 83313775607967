<template>
  <div class="dashboard">
    <HeaderComponent />
    <div class="content-wrapper">
      <SidebarComponent 
        :disabled="!isAuthenticated" 
        @toggle="toggleSidebar"
      />
      <div class="main-content" :class="{ 'sidebar-collapsed': isSidebarCollapsed }">
        <router-view :isSidebarCollapsed="isSidebarCollapsed" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import SidebarComponent from '@/components/SidebarComponent.vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import { useAuthStore } from '@/store/auth';

export default {
  name: 'DashboardView',
  components: {
    SidebarComponent,
    HeaderComponent
  },
  setup() {
    const authStore = useAuthStore();
    const isAuthenticated = ref(false);
    const isSidebarCollapsed = ref(false);

    const checkAuth = () => {
      isAuthenticated.value = !!authStore.user;
    };

    const toggleSidebar = (collapsed) => {
      isSidebarCollapsed.value = collapsed;
    };

    onMounted(checkAuth);
    watch(() => authStore.user, checkAuth);

    return {
      isAuthenticated,
      isSidebarCollapsed,
      toggleSidebar
    };
  }
}
</script>

<style scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.main-content {
  flex: 1;
  padding: 0;
  background-color: #f4f5f7;
  overflow-y: auto;
  margin-left: 260px; /* Width of expanded sidebar */
  transition: margin-left 0.3s, width 0.3s;
  width: calc(100% - 260px);
}

.main-content.sidebar-collapsed {
  margin-left: 60px; /* Width of collapsed sidebar */
  width: calc(100% - 60px);
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: row;
  }

  .main-content {
    margin-left: 60px; /* Width of collapsed sidebar on mobile */
    width: calc(100% - 60px);
    padding-top: 64px; /* Height of the header */
    height: calc(100vh - 64px); /* Subtract header height */
  }

  .main-content.sidebar-collapsed {
    margin-left: 60px; /* Keep the same margin when collapsed */
    width: calc(100% - 60px);
  }
}

@media (max-width: 360px) {
  .main-content,
  .main-content.sidebar-collapsed {
    margin-left: 50px; /* Width of collapsed sidebar on very small screens */
    width: calc(100% - 50px);
  }
}
</style>
