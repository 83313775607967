import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import { supabase } from './supabase'
import { useAuthStore } from './store/auth'
import { useCreditsStore } from './store/credits'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'remixicon/fonts/remixicon.css'  

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
app.use(router)

library.add(faGoogle, faFacebook)

app.component('font-awesome-icon', FontAwesomeIcon)

app.provide('supabase', supabase)

app.directive('auth-check', {
  mounted(el) {
    el.addEventListener('click', (event) => {
      const authStore = useAuthStore()
      const openAuthModal = app.config.globalProperties.$openAuthModal
      if (!authStore.user && !el.classList.contains('auth-exempt')) {
        event.preventDefault()
        event.stopPropagation()
        openAuthModal()
      }
    })
  }
})

const authStore = useAuthStore()
const creditsStore = useCreditsStore()

async function initializeApp() {
  try {
    await authStore.initializeAuth()
    if (authStore.user) {
      await creditsStore.fetchCredits(authStore.user.id)
    }
  } catch (error) {
    console.error('Error initializing app:', error)
  } finally {
    app.mount('#app')
  }
}

initializeApp()

app.config.globalProperties.$openAuthModal = () => {
  const authStore = useAuthStore()
  if (!authStore.user) {
    authStore.showAuthModal = true
  }
}
