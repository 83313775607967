<template>
    <div class="canceled-view">
      <HeaderComponent />
      <div class="content-container">
        <div class="message-box">
          <div class="emoji">😅</div>
          <h1>Oops! Payment Canceled</h1>
          <p>No worries! Your payment was canceled and no charges were made.</p>
          <router-link to="/buy-credits" class="return-button">Give it another shot?</router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import HeaderComponent from '@/components/HeaderComponent.vue'

  export default {
    name: 'CanceledView',
    components: {
      HeaderComponent
    }
  }
  </script>

  <style scoped>
  .canceled-view {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .content-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }

  .message-box {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }

  .emoji {
    font-size: 5rem;
    margin-bottom: 1rem;
  }

  h1 {
    color: #e74c3c;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  p {
    color: #34495e;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }

  .return-button {
    display: inline-block;
    background-color: #3498db;
    color: white;
    text-decoration: none;
    padding: 0.8rem 1.5rem;
    border-radius: 30px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }

  .return-button:hover {
    background-color: #2980b9;
  }
  </style>