import { createRouter, createWebHistory } from 'vue-router';
import { supabase } from '@/supabase' // Add this import
import DashboardView from '@/views/DashboardView.vue';
import LoginView from '@/views/LoginView.vue';
import SignupView from '@/views/SignupView.vue';
import FaceImproveView from '@/views/FaceImproveView.vue';
import ImproveImageQualityView from '@/views/ImproveImageQualityView.vue';
import ChangeClothes from '@/views/ChangeClothes.vue';
import LightChangeView from '@/views/LightChangeView.vue';
import RegisterView from '@/views/RegisterView.vue';
import ConfirmEmailView from '@/views/ConfirmEmailView.vue';
import SuccessView from '@/views/SuccessView.vue';
import CanceledView from '@/views/CanceledView.vue';
import SubscriptionsView from '@/views/SubscriptionsView.vue';
import ForgotPasswordView from '../views/ForgotPasswordView.vue';
import ResetPasswordView from '../views/ResetPasswordView.vue';

const routes = [
  {
    path: '/',
    component: DashboardView,
    children: [
      {
        path: '',
        name: 'Home',
        component: ChangeClothes
      },
      {
        path: 'change-clothes',
        name: 'ChangeClothes',
        component: ChangeClothes,
        meta: { requiresAuth: true }
      },
      {
        path: 'face-improve',
        name: 'FaceImproveView',
        component: FaceImproveView,
        meta: { requiresAuth: true }
      },
      {
        path: 'improve-image-quality',
        name: 'ImproveImageQualityView',
        component: ImproveImageQualityView,
        meta: { requiresAuth: true }
      },
      {
        path: 'light-change',
        name: 'LightChange',
        component: LightChangeView,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/signup',
    name: 'Signup',
    component: SignupView
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView
  },
  {
    path: '/buy-credits',
    name: 'BuyCredits',
    component: () => import('@/views/BuyCreditsView.vue'),
    meta: { requiresAuth: true } // Add this meta field
  },
  {
    path: '/confirm-email',
    name: 'ConfirmEmail',
    component: ConfirmEmailView
  },
  {
    path: '/success',
    name: 'Success',
    component: SuccessView
  },
  {
    path: '/canceled',
    name: 'Canceled',
    component: CanceledView
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: SubscriptionsView
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordView
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPasswordView
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Add this navigation guard
router.beforeEach(async (to, from, next) => {
  const { data: { session } } = await supabase.auth.getSession()
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !session) {
    next('/')
  } else {
    next()
  }
})

export default router;