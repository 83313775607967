<template>
  <div class="image-improve">
    <div class="image-container">
      <!-- Input Image Column -->
      <div class="image-box">
        <h3>1. Input Image</h3>
        <div 
          class="dropzone"
          @click="triggerFileInput"
          @dragover.prevent
          @drop.prevent="handleDrop"
        >
          <img v-if="inputImage" :src="inputImage" alt="Input Image" />
          <div v-else class="placeholder">
            <i class="mdi mdi-upload"></i>
            <span>Click or Drag-n-Drop to Upload</span>
          </div>
        </div>
        <input type="file" ref="fileInput" @change="onFileChange" style="display: none;" />
        <div class="actions">
          <button class="delete-btn" @click.stop="clearImage" v-if="inputImage">
            <i class="mdi mdi-delete"></i> Clear
          </button>
        </div>
      </div>
      
      <!-- Upscaled Image Column -->
      <div class="image-box">
        <h3>2. Upscaled Image</h3>
        <div class="dropzone result-zone">
          <img v-if="outputImage" :src="outputImage" alt="Upscaled Image" />
          <div v-else-if="isLoading" class="placeholder loading">
            <div class="spinner"></div>
            <span>Generating image...</span>
          </div>
          <div v-else-if="error" class="placeholder error">
            <span>😕 {{ error }}</span>
            <button v-if="showRechargeButton" @click="goToBuyCredits" class="recharge-btn">Recharge Credits</button>
          </div>
          <div v-else class="placeholder">
            <span>Generated image will appear here</span>
          </div>
        </div>
        <div class="actions" v-if="outputImage">
          <button class="download-btn" @click="downloadImage">
            <i class="mdi mdi-download"></i> Download
          </button>
        </div>
      </div>
    </div>
    
    <button @click="generateImage" :disabled="!inputImage || isLoading" class="generate-btn">
      {{ isLoading ? 'Generating...' : 'Generate (1 Credit)' }}
    </button>

    <p v-if="error" class="error-message">{{ error }}</p>

    <!-- Example Images Section -->
    <div class="example-section">
      <h3>Examples</h3>
      <div class="example-container">
        <div class="example-item">
          <h4>Input Image:</h4>
          <img src="/Images/examples/changeclothes-result.png" alt="Example input image" class="example-image">
        </div>
        <div class="example-item">
          <h4>Improved Image (clarity & scale):</h4>
          <img src="/Images/examples/improvedquality-final.jpeg" alt="Example improved image" class="example-image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
import { supabase } from '../supabase';
import { useCreditsStore } from '../store/credits';

export default {
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      inputImage: null,
      outputImage: null,
      base64Image: null,
      error: null,
      isLoading: false,
      apiKey: process.env.SEGMIND_API_KEY,
      userId: null,
      showRechargeButton: false
    };
  },
  async created() {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      this.userId = user.id;
    }
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.inputImage = URL.createObjectURL(file);
      this.convertToBase64(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.inputImage = URL.createObjectURL(file);
      this.convertToBase64(file);
    },
    clearImage() {
      this.inputImage = null;
      this.outputImage = null;
      this.error = null;
    },
    async convertToBase64(file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.base64Image = reader.result.split(',')[1];
      };
      reader.readAsDataURL(file);
    },
    async generateImage() {
      console.log('Generate image method called');
      if (!this.inputImage) {
        this.error = '🙈 Oops! Please upload an image before generating.';
        this.showRechargeButton = false;
        return;
      }

      const creditsStore = useCreditsStore();
      await creditsStore.fetchCredits(this.userId);
      const userCredits = creditsStore.credits;
      console.log('User credits:', userCredits);

      if (userCredits < 1) {
        this.error = '💸 Oh no! You need more credits to generate an image.';
        this.showRechargeButton = true;
        return;
      }

      this.showRechargeButton = false;
      this.isLoading = true;
      this.outputImage = null;
      this.error = null;

      try {
        let apiUrl;
        const hostname = window.location.hostname;
        if (hostname === 'localhost') {
          apiUrl = 'http://localhost:3000/api/improveImageQuality';
        } else if (hostname === 'staging.app.profilebakery.com') {
          apiUrl = 'https://staging.app.profilebakery.com/api/improveImageQuality';
        } else if (hostname === 'new.app.profilebakery.com') {
          apiUrl = 'https://new.app.profilebakery.com/api/improveImageQuality';
        } else {
          // Default to production URL if hostname is not recognized
          apiUrl = 'https://new.app.profilebakery.com/api/improveImageQuality';
        }
        
        console.log('API URL:', apiUrl);

        const payload = {
          image: this.base64Image,
          scale: 2,
          user_id: this.userId
        };

        const response = await axios.post(apiUrl, payload, {
          responseType: 'arraybuffer',
          timeout: 300000, // 5 minutes timeout
          withCredentials: true
        });

        // Create a blob from the arraybuffer response
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        this.outputImage = URL.createObjectURL(blob);

        // Refresh credits after successful generation
        await creditsStore.fetchCredits(this.userId);
        console.log('Updated user credits:', creditsStore.credits);

      } catch (error) {
        console.error('Error details:', error);
        if (error.response) {
          console.error('Error status:', error.response.status);
          if (error.response.status === 504) {
            this.error = '⏳ The request timed out. The image might be too large or the server is busy. Please try again later.';
          } else {
            this.error = `🚫 An error occurred (Status ${error.response.status}). Please try again.`;
          }
        } else if (error.request) {
          this.error = '🌐 No response received from the server. Please check your internet connection and try again.';
        } else {
          this.error = `🚫 An error occurred: ${error.message}`;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async deductCredits() {
      const creditsStore = useCreditsStore();
      try {
        await creditsStore.deductCredits(this.userId, 1);
        console.log('Credits deducted successfully');
      } catch (error) {
        console.error('Error deducting credits:', error);
        this.error = 'Failed to deduct credits. Please try again or contact support.';
      }
    },
    downloadImage() {
      if (this.outputImage) {
        const link = document.createElement('a');
        link.href = this.outputImage;
        link.download = 'upscaled_image.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    goToBuyCredits() {
      this.$router.push('/buy-credits');
    }
  }
};
</script>
<style scoped>
.image-improve {
  max-width: 100%;
  padding: 1rem;
  transition: all 0.3s;
  box-sizing: border-box;
}

.image-container {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.image-box {
  flex: 1;
  min-width: 0; /* This allows the box to shrink below its content size */
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 1rem;
  cursor: pointer;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.dropzone.result-zone {
  cursor: default;
}

.dropzone img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: auto;
  height: auto;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #ccc;
}

.placeholder i {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.placeholder span {
  font-size: 1.2rem;
}

.actions {
  margin-top: 1rem;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.delete-btn i {
  margin-right: 0.75rem;
  font-size: 1.2rem;
}

.generate-btn {
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.generate-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.generate-btn:hover:not(:disabled) {
  background-color: #218838;
}

@media (max-width: 1200px) {
  .image-container {
    flex-direction: column;
  }

  .image-box {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 768px) {
  .image-improve {
    padding: 1rem;
  }

  .image-box {
    padding: 1rem;
  }

  .dropzone {
    height: 180px;
  }

  .generate-btn {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  .image-improve {
    padding: 0.5rem;
  }

  .image-box {
    padding: 0.5rem;
  }

  .dropzone {
    height: 150px;
  }

  h3 {
    font-size: 1rem;
  }

  .placeholder i {
    font-size: 2rem;
  }

  .placeholder span {
    font-size: 0.8rem;
  }

  .generate-btn {
    font-size: 0.8rem;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message a {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.recharge-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.recharge-btn:hover {
  background-color: #0056b3;
}

.download-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.download-btn:hover {
  background-color: #0056b3;
}

@media (max-width: 1400px) {
  .image-improve {
    padding: 1.5rem;
  }
}

@media (max-width: 1200px) {
  .image-container {
    flex-wrap: wrap;
  }

  .image-box {
    flex-basis: calc(50% - 0.75rem);
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 768px) {
  .image-improve {
    padding: 1rem;
  }

  .image-container {
    flex-direction: column;
    gap: 1rem;
  }

  .image-box {
    width: 100%;
    flex-basis: 100%;
  }
}

@media (max-width: 480px) {
  .image-improve {
    padding: 0.5rem;
  }

  h3 {
    font-size: 1rem;
  }

  .dropzone {
    height: 150px;
  }

  .placeholder i {
    font-size: 2rem;
  }

  .placeholder span {
    font-size: 0.8rem;
  }
}

.example-section {
  margin-top: 2rem;
}

.example-section h3 {
  text-align: center;
  margin-bottom: 1rem;
}

.example-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.example-item {
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.example-item h4 {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.example-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

@media (max-width: 768px) {
  .example-container {
    flex-direction: column;
  }

  .example-item {
    margin-bottom: 1rem;
  }
}
</style>