<template>
  <div id="app">
    <ErrorBoundary>
      <Suspense>
        <template #default>
          <router-view />
        </template>
        <template #fallback>
          <div>Loading...</div>
        </template>
      </Suspense>
    </ErrorBoundary>
    <ModalDialog :show="showAuthModal" @close="closeAuthModal">
      <component :is="currentAuthComponent"
                 :close="closeAuthModal"
                 :switchToSignup="() => authMode = 'signup'"
                 :switchToLogin="() => authMode = 'login'"
                 :switchToForgotPassword="() => authMode = 'forgotPassword'" />
    </ModalDialog>
  </div>
</template>

<script>
import { ref, provide, watch, onMounted, defineAsyncComponent, computed } from 'vue'
import ModalDialog from './components/Modal.vue'
import { useAuthStore } from './store/auth'
import { storeToRefs } from 'pinia'
import ErrorBoundary from './components/ErrorBoundary.vue'

const SignupView = defineAsyncComponent(() => import('./views/SignupView.vue'))
const LoginView = defineAsyncComponent(() => import('./views/LoginView.vue'))
const ForgotPasswordView = defineAsyncComponent(() => import('./views/ForgotPasswordView.vue'))

export default {
  name: 'App',
  components: {
    ModalDialog,
    ErrorBoundary
  },
  setup() {
    const authStore = useAuthStore()
    const { user, showAuthModal } = storeToRefs(authStore)
    const authMode = ref('login')

    const currentAuthComponent = computed(() => {
      switch (authMode.value) {
        case 'signup':
          return SignupView
        case 'forgotPassword':
          return ForgotPasswordView
        default:
          return LoginView
      }
    })

    const openAuthModal = (mode = 'login') => {
      showAuthModal.value = true
      authMode.value = mode
    }

    const closeAuthModal = () => {
      showAuthModal.value = false
    }

    provide('openAuthModal', openAuthModal)
    provide('closeAuthModal', closeAuthModal)

    watch(user, (newUser) => {
      if (newUser) {
        showAuthModal.value = false
      }
    })

    onMounted(() => {
      authStore.initializeAuth()
    })

    return {
      showAuthModal,
      closeAuthModal,
      authMode,
      currentAuthComponent
    }
  }
}
</script>

<style>
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

#app {
  height: 100vh;
}
</style>