<template>
    <div class="auth-container">
      <h2 class="auth-title">Forgot Password</h2>
      <p class="auth-subtitle">Enter your email to reset your password</p>
      
      <form v-if="!isSuccess" @submit.prevent="handleForgotPassword" class="auth-form">
        <div class="form-group">
          <label for="email">Email</label>
          <input id="email" v-model="email" type="email" placeholder="you@example.com" required>
          <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
        </div>
        <span v-if="errors.general" class="error-message">{{ errors.general }}</span>
        <button type="submit" class="btn btn-primary" :disabled="isLoading || cooldownTime > 0">
          {{ isLoading ? 'Sending...' : cooldownTime > 0 ? `Try again in ${cooldownTime}s` : 'Send Reset Link' }}
        </button>
      </form>

      <div v-else class="success-message">
        <p>Password reset email sent. Please check your inbox.</p>
        <button @click="$props.switchToLogin" class="btn btn-secondary">Back to Login</button>
      </div>

      <div v-if="!isSuccess" class="auth-footer">
        <p>Remember your password?</p>
        <button @click="$props.switchToLogin" class="btn btn-secondary">Log in</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { useAuthStore } from '../store/auth'

  export default {
    name: 'ForgotPasswordView',
    props: {
      switchToLogin: Function
    },
    setup() {
      const authStore = useAuthStore()
      const email = ref('')
      const errors = ref({})
      const isLoading = ref(false)
      const isSuccess = ref(false)
      const cooldownTime = ref(0)

      const validateForm = () => {
        errors.value = {}
        if (!email.value) errors.value.email = 'Email is required'
        return Object.keys(errors.value).length === 0
      }

      const handleForgotPassword = async () => {
        if (!validateForm() || cooldownTime.value > 0) return

        isLoading.value = true
        try {
          await authStore.resetPasswordForEmail(email.value)
          isSuccess.value = true
          startCooldown(60) // 60 seconds cooldown
        } catch (error) {
          console.error('Password reset error:', error)
          if (error.code === 'over_email_send_rate_limit') {
            errors.value.general = 'Too many requests. Please try again later.'
            startCooldown(300) // 5 minutes cooldown for rate limit error
          } else {
            errors.value.general = error.message || 'An error occurred. Please try again.'
            startCooldown(30) // 30 seconds cooldown for other errors
          }
        } finally {
          isLoading.value = false
        }
      }

      const startCooldown = (seconds) => {
        cooldownTime.value = seconds
        const timer = setInterval(() => {
          cooldownTime.value--
          if (cooldownTime.value <= 0) {
            clearInterval(timer)
          }
        }, 1000)
      }

      return {
        email,
        errors,
        isLoading,
        isSuccess,
        cooldownTime,
        handleForgotPassword
      }
    }
  }
  </script>
  
  <style scoped>
  /* Use the same styles as LoginView.vue */
  .auth-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .auth-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.5rem;
  }

  .auth-subtitle {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1.5rem;
  }

  .auth-form {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  input {
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: 400;
    color: #333;
    background-color: #fff;
    box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.1);
  }

  .btn {
    display: inline-block;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    background-color: #333;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }

  .btn-primary {
    background-color: #007bff;
  }

  .btn-secondary {
    background-color: #6c757d;
  }

  .btn:hover {
    background-color: #333;
  }

  .error-message {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }

  .success-message {
    text-align: center;
    margin-top: 2rem;
  }

  .success-message p {
    font-size: 1.1rem;
    color: #28a745;
    margin-bottom: 1rem;
  }
  </style>