<template>
  <div class="app-container">
    <HeaderComponent />
    <div class="content-container">
      <SidebarComponent class="sidebar" />
      <div class="main-content">
        <div class="subscription-view">
          <h1 class="title">Subscription Management</h1>
          
          <div v-if="error" class="error-message">
            <p>{{ error }}</p>
            <router-link v-if="error === 'No Subscription or Account found yet.'" to="/buy-credits" class="btn btn-primary">
              Buy Credits / Subscribe
            </router-link>
          </div>

          <div v-else>
            <!-- Commented out subscription details to resolve ESLint error -->
            <!--
            <div v-if="subscription">
              <h2>Current Subscription</h2>
              <p>Status: {{ subscription.status || 'N/A' }}</p>
              <p>Next billing date: {{ formatDate(subscription.current_period_end) }}</p>
            </div>
            <div v-else>
              <p>You don't have an active subscription.</p>
            </div>
            -->

            <button @click="openStripePortal" :disabled="isRedirecting" class="btn btn-primary">
              {{ isRedirecting ? 'Redirecting...' : 'Manage Subscription' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { supabase } from '@/supabase'
import { useStripe } from '@/store/stripe'
import HeaderComponent from '@/components/HeaderComponent.vue'  
import SidebarComponent from '@/components/SidebarComponent.vue'

// eslint-disable-next-line no-unused-vars
const subscription = ref(null)
// eslint-disable-next-line no-unused-vars
const loading = ref(true)
const error = ref(null)
const isRedirecting = ref(false)

const { getStripeCustomerId } = useStripe()

onMounted(async () => {
  // If loadUserData is not defined or not needed, you can remove this line
  // await loadUserData()
  
  // If you need to load user data, you might want to implement it like this:
  // try {
  //   const { data: { session } } = await supabase.auth.getSession()
  //   if (session) {
  //     // Load user data or subscription info here
  //   }
  // } catch (err) {
  //   console.error('Error loading user data:', err)
  //   error.value = 'Error loading user data'
  // } finally {
  //   loading.value = false
  // }
})

const openStripePortal = async () => {
  try {
    isRedirecting.value = true
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      throw new Error('No active session found');
    }

    const stripeCustomerId = await getStripeCustomerId(session.user.id);
    if (!stripeCustomerId) {
      error.value = 'No Subscription or Account found yet.';
      return;
    }

    const response = await fetch('/api/create-portal-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.access_token}`
      },
      body: JSON.stringify({ stripeCustomerId })
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to create portal session');
    }
    const { url } = await response.json();
    window.location.href = url;
  } catch (err) {
    error.value = err.message;
  } finally {
    isRedirecting.value = false
  }
}
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  display: flex;
  flex: 1;
}

.main-content {
  flex: 1;
  padding: 2rem;
  background-color: #ffffff;
  transition: margin-left 0.3s;
  overflow-x: hidden;
}

.subscription-view {
  max-width: 800px;
  margin: 0 auto;
  text-align: center; /* Center the content */
}

.title {
  font-size: 2rem;
  margin-bottom: 2rem; /* Increased margin for better spacing */
  color: #333;
  text-align: center;
}

.loading, .error-message {
  text-align: center;
  margin: 2rem 0;
}

.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.subscription-info {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem; /* Slightly larger padding */
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin: 1rem auto; /* Center the button and add vertical margin */
  transition: background-color 0.3s;
  font-size: 1.1rem; /* Slightly larger font size */
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-primary:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #495057;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
  }

  .main-content {
    padding: 1rem;
    margin-left: 80px; /* Width of collapsed sidebar on mobile */
  }
}

@media (max-width: 480px) {
  .main-content {
    padding: 0.5rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .btn-primary {
    padding: 0.6rem 1.2rem; /* Adjust padding for smaller screens */
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
}
</style>