<template>
  <div class="auth-container">
    <h2 class="auth-title">Create an Account</h2>
    <p class="auth-subtitle">Sign up to get started</p>
    
    <!-- Add this error message display -->
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
    
    <form @submit.prevent="handleSignup" class="auth-form">
      <div class="form-group">
        <label for="name">Full Name</label>
        <input id="name" v-model="name" type="text" placeholder="John Doe" required>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" v-model="email" type="email" placeholder="you@example.com" required>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input id="password" v-model="password" type="password" placeholder="••••••••" required>
      </div>
      <button type="submit" class="btn btn-primary" :disabled="isLoading">
        {{ isLoading ? 'Signing Up...' : 'Sign Up' }}
      </button>
      <div v-if="isLoading" class="loader-container">
        <div class="loader"></div>
        <p>Creating your account...</p>
      </div>
    </form>

    <!-- Google and Facebook buttons are commented out for future implementation -->
    <!--
    <div class="auth-divider">
      <span>or</span>
    </div>

    <div class="social-auth">
      <button @click="handleGoogleAuth" class="btn btn-social btn-google">
        <font-awesome-icon :icon="['fab', 'google']" class="social-icon" />
        Google
      </button>
      <button @click="handleFacebookAuth" class="btn btn-social btn-facebook">
        <font-awesome-icon :icon="['fab', 'facebook']" class="social-icon" />
        Facebook
      </button>
    </div>
    -->

    <div class="auth-footer">
      <p>Already have an account?</p>
      <button @click="switchToLogin" class="btn btn-secondary">Log in</button>
    </div>

    <Modal :show="showConfirmationModal" @close="showConfirmationModal = false">
      <div class="confirmation-modal">
        <div class="emoji">📧</div>
        <h2>Woohoo! You're Almost There!</h2>
        <p>We've sent a magical email to <strong>{{ email }}</strong></p>
        <p>Click the enchanted link in the email to activate your account and start your journey!</p>
        <button @click="showConfirmationModal = false" class="btn btn-primary">Got it, thanks!</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useAuthStore } from '../store/auth'
import Modal from '@/components/Modal.vue'

export default {
  name: 'SignupView',
  components: {
    Modal
  },
  props: {
    close: {
      type: Function,
      required: true
    },
    switchToLogin: {
      type: Function,
      required: true
    }
  },
  setup() {
    const authStore = useAuthStore()
    const name = ref('')
    const email = ref('')
    const password = ref('')
    const showConfirmationModal = ref(false)
    const isLoading = ref(false)
    const errorMessage = ref('')

    const handleSignup = async () => {
      isLoading.value = true
      errorMessage.value = '' // Clear any previous error messages
      try {
        const { user } = await authStore.signup(email.value, password.value, name.value)
        
        // Call the create-user-credits function
        const response = await fetch('/api/create-user-credits', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: user.id }),
        })

        if (!response.ok) {
          const errorData = await response.json()
          throw new Error(errorData.error || 'Failed to create initial credits')
        }

        showConfirmationModal.value = true
      } catch (error) {
        console.error('Signup error:', error)
        if (error.message.includes('violates foreign key constraint')) {
          errorMessage.value = 'Email already used. Please login instead.'
        } else if (error.code === 'email_address_not_authorized') {
          errorMessage.value = 'This email address is not authorized for signup on this environment. Please use an authorized email or contact support.'
        } else {
          errorMessage.value = error.message || 'An error occurred during signup. Please try again.'
        }
      } finally {
        isLoading.value = false
      }
    }

    return { 
      name, 
      email, 
      password, 
      handleSignup,
      showConfirmationModal,
      isLoading,
      errorMessage
    }
  }
}
</script>

<style scoped>
.auth-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.auth-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.auth-subtitle {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.auth-form {
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 0.5rem;
}

input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #007bff;
}

.btn {
  display: inline-block;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  width: 100%;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.auth-divider {
  text-align: center;
  margin: 1.5rem 0;
  position: relative;
}

.auth-divider::before,
.auth-divider::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #ddd;
}

.auth-divider::before {
  left: 0;
}

.auth-divider::after {
  right: 0;
}

.auth-divider span {
  display: inline-block;
  padding: 0 0.5rem;
  background-color: #fff;
  position: relative;
  color: #666;
  font-size: 0.9rem;
}

.social-auth {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.btn-social {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  padding: 0.75rem;
  font-size: 0.9rem;
  margin: 0 0.5rem;
}

.btn-google {
  background-color: #db4437;
}

.btn-facebook {
  background-color: #4267B2;
}

.social-icon {
  width: 18px;
  height: 18px;
  margin-right: 0.5rem;
}

.auth-footer {
  text-align: center;
  font-size: 0.9rem;
  color: #666;
}

.auth-footer a {
  color: #007bff;
  text-decoration: none;
}

.auth-footer a:hover {
  text-decoration: underline;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.confirmation-modal {
  text-align: center;
  padding: 2rem;
}

.confirmation-modal .emoji {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.confirmation-modal h2 {
  color: #007bff;
  margin-bottom: 1rem;
}

.confirmation-modal p {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.confirmation-modal .btn {
  margin-top: 1.5rem;
}

.error-message {
  color: #dc3545;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 0.9rem;
}
</style>