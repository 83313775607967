<template>
    <div class="auth-container">
      <h2 class="auth-title">Reset Password</h2>
      <p class="auth-subtitle">Enter your new password</p>
      
      <form @submit.prevent="handleResetPassword" class="auth-form">
        <div class="form-group">
          <label for="password">New Password</label>
          <input id="password" v-model="password" type="password" placeholder="••••••••" required>
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm New Password</label>
          <input id="confirmPassword" v-model="confirmPassword" type="password" placeholder="••••••••" required>
        </div>
        <span v-if="error" class="error-message">{{ error }}</span>
        <button type="submit" class="btn btn-primary" :disabled="isLoading">
          {{ isLoading ? 'Resetting...' : 'Reset Password' }}
        </button>
      </form>

      <div class="auth-footer">
        <p>Remember your password?</p>
        <button @click="goToLogin" class="btn btn-secondary">Log in</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useAuthStore } from '../store/auth'
  
  export default {
    name: 'ResetPasswordView',
    setup() {
      const route = useRoute()
      const router = useRouter()
      const authStore = useAuthStore()
      const password = ref('')
      const confirmPassword = ref('')
      const error = ref('')
      const isLoading = ref(false)
  
      onMounted(() => {
        if (!route.hash) {
          router.push('/login')
        }
      })
  
      const handleResetPassword = async () => {
        if (password.value !== confirmPassword.value) {
          error.value = 'Passwords do not match'
          return
        }

        isLoading.value = true
        error.value = ''
        try {
          await authStore.resetPassword(route.hash, password.value)
          alert('Password reset successfully. You will now be redirected to the homepage.')
          router.push('/')  // This line now directs the user to the homepage
        } catch (err) {
          error.value = err.message || 'An error occurred. Please try again.'
        } finally {
          isLoading.value = false
        }
      }
  
      const goToLogin = () => {
        router.push('/login')
      }

      return {
        password,
        confirmPassword,
        error,
        isLoading,
        handleResetPassword,
        goToLogin
      }
    }
  }
  </script>
  
  <style scoped>
  .auth-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .auth-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.5rem;
  }

  .auth-subtitle {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1.5rem;
  }

  .auth-form {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  input {
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: 400;
    color: #333;
    background-color: #fff;
    box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.1);
  }

  .btn {
    display: inline-block;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    background-color: #333;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }

  .btn-primary {
    background-color: #007bff;
    width: 100%;
  }

  .btn-secondary {
    background-color: #6c757d;
  }

  .btn:hover {
    opacity: 0.9;
  }

  .btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .error-message {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  }

  .auth-footer {
    text-align: center;
    margin-top: 1.5rem;
    font-size: 0.9rem;
    color: #666;
  }

  .auth-footer p {
    margin-bottom: 0.5rem;
  }
  </style>