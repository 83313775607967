<template>
  <aside id="sidebar" class="sidebar" :class="{ 'collapsed': isCollapsed, 'mobile': isMobile }">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <div class="pro-sidebar-logo">
          <router-link to="/" class="logo-link">
            <span class="logo-icon">👩‍🍳</span>
            <span class="logo-text">Profile Bakery</span>
          </router-link>
        </div>
      </div>
      <div class="sidebar-content">
        <nav class="menu">
          <ul>
            <li class="menu-header"><span>GENERAL</span></li>
            <li v-for="(item, index) in menuItems" :key="index" class="menu-item">
              <router-link 
                :to="item.path" 
                class="menu-link" 
                :class="{ 'active': route.path === item.path }"
                @click="handleMenuItemClick"
              >
                <span class="menu-icon">
                  <i :class="item.icon"></i>
                </span>
                <span class="menu-title">
                  {{ item.text }}
                  <span v-if="item.beta" class="beta-label">Beta</span>
                </span>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <button class="sidebar-collapser" @click="toggleSidebar">
      <i :class="isCollapsed ? 'ri-arrow-right-s-line' : 'ri-arrow-left-s-line'"></i>
    </button>
  </aside>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'SidebarComponent',
  setup(props, { emit }) {
    const isCollapsed = ref(false);
    const isMobile = ref(false);
    const route = useRoute();

    const menuItems = [
      { path: '/change-clothes', icon: 'ri-t-shirt-line', text: 'Change Clothes' },
      { path: '/face-improve', icon: 'ri-emotion-line', text: 'Face Improve' },
      { path: '/improve-image-quality', icon: 'ri-image-line', text: 'Improve Image Quality' },
      { path: '/light-change', icon: 'ri-contrast-2-line', text: 'Light Change', beta: true },
    ];

    const toggleSidebar = () => {
      isCollapsed.value = !isCollapsed.value;
      emit('toggle', isCollapsed.value);
    };

    const handleMenuItemClick = () => {
      if (isMobile.value && !isCollapsed.value) {
        isCollapsed.value = true;
      }
    };

    const checkMobile = () => {
      isMobile.value = window.innerWidth <= 768;
      if (isMobile.value) {
        isCollapsed.value = true;
      }
    };

    onMounted(() => {
      checkMobile();
      window.addEventListener('resize', checkMobile);
    });

    return {
      isCollapsed,
      isMobile,
      menuItems,
      toggleSidebar,
      handleMenuItemClick,
      route
    };
  }
}
</script>

<style scoped>
.sidebar {
  width: 260px; /* Keep the original width */
  background-color: #0c1e35;
  color: #7d84ab;
  height: 100vh;
  transition: all 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

.sidebar.collapsed {
  width: 60px; /* Make the collapsed sidebar smaller */
}

.sidebar-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar-header {
  padding: 0;
  text-align: left;
}

.pro-sidebar-logo {
  display: flex;
  align-items: center;
  height: 64px; /* Match header height */
  padding: 0 25px; /* Align with menu items */
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  transition: opacity 0.3s ease;
}

.logo-link:hover {
  opacity: 0.8;
}

.logo-icon {
  font-size: 1.5rem; /* Increased from 1.25rem */
  margin-right: 12px; /* Slightly increased from 10px */
  display: flex;
  align-items: center;
}

.logo-text {
  font-size: 1.25rem;
  font-weight: bold;
  white-space: nowrap;
  line-height: 1; /* Added to align text vertically */
}

.collapsed .pro-sidebar-logo {
  justify-content: center;
  padding: 0;
}

.collapsed .logo-text {
  display: none;
}

.collapsed .logo-icon {
  margin-right: 0;
}

.sidebar-content {
  flex-grow: 1;
  padding: 10px 0;
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-header {
  font-weight: 600;
  padding: 10px 25px; /* Match padding with logo */
  font-size: 0.8em;
  letter-spacing: 2px;
  opacity: 0.5;
}

.collapsed .menu-header {
  display: none;
}

.menu-item {
  margin-bottom: 5px;
}

.menu-link {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 25px; /* Match padding with logo and menu header */
  color: #a0a5b9;
  text-decoration: none;
  transition: all 0.3s;
}

.menu-link:hover, .menu-link.router-link-active {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.menu-icon {
  font-size: 1.2rem;
  width: 35px;
  min-width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
}

.menu-title {
  white-space: nowrap;
  font-weight: 500;
}

.collapsed .menu-link {
  justify-content: center;
  padding: 0;
}

.collapsed .menu-icon {
  margin-right: 0;
  width: 100%;
}

.collapsed .menu-title {
  display: none;
}

.beta-label {
  background-color: #28a745;
  color: white;
  font-size: 0.7em;
  padding: 2px 6px;
  border-radius: 10px;
  margin-left: 8px;
  font-weight: bold;
  text-transform: uppercase;
}

.collapsed .beta-label {
  display: none;
}

.sidebar-collapser {
  position: absolute;
  right: -15px;
  top: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #00829f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  transform: translateX(50%);
  cursor: pointer;
  color: white;
  border: none;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .sidebar {
    width: 260px;
  }

  .sidebar.collapsed {
    width: 60px;
  }

  .sidebar .pro-sidebar-logo {
    justify-content: center;
    padding: 0;
  }

  .sidebar .logo-text,
  .sidebar .menu-title,
  .sidebar .menu-header {
    display: none;
  }

  .sidebar .logo-icon,
  .sidebar .menu-icon {
    margin-right: 0;
  }

  .sidebar .menu-link {
    justify-content: center;
    padding: 0;
  }

  .sidebar:not(.collapsed) .pro-sidebar-logo {
    justify-content: flex-start;
    padding: 0 25px;
  }

  .sidebar:not(.collapsed) .logo-text,
  .sidebar:not(.collapsed) .menu-title,
  .sidebar:not(.collapsed) .menu-header {
    display: inline;
  }

  .sidebar:not(.collapsed) .logo-icon,
  .sidebar:not(.collapsed) .menu-icon {
    margin-right: 12px;
  }

  .sidebar:not(.collapsed) .menu-link {
    justify-content: flex-start;
    padding: 0 25px;
  }

  .sidebar-collapser {
    right: -10px; /* Adjusted to prevent overlap */
    width: 20px; /* Smaller collapser button */
    height: 20px; /* Smaller collapser button */
    font-size: 0.9em; /* Smaller icon */
  }
}

@media (max-width: 360px) {
  .sidebar {
    width: 260px;
  }

  .sidebar.collapsed {
    width: 50px;
  }

  .sidebar-collapser {
    right: -8px;
    width: 16px;
    height: 16px;
    font-size: 0.8em;
  }
}
</style>