<template>
  <header class="header">
    <div class="spacer"></div>
    <div class="actions">
      <a href="mailto:support@profilebakery.com" class="feedback-btn">
        <i class="ri-feedback-line"></i> Feedback
      </a>
      <div v-if="user" class="user-info">
        <span :class="{ 'credits-low': credits <= 0 }" class="user-credits">
          <i class="ri-coin-line"></i> <!-- Added icon for credits -->
          {{ credits }}
        </span>
        <button v-if="credits <= 0" @click="goToBuyCredits" class="btn-buy-credits">
          <i class="ri-shopping-cart-line"></i> Get Credits
        </button>
        <div class="user-menu" @click="toggleDropdown">
          <img :src="require('@/assets/default-avatar.png')" alt="User Avatar" class="user-avatar">
          <div v-if="showDropdown" class="dropdown-menu">
            <div class="dropdown-item greeting">👋 {{ displayName }}</div>
            <router-link to="/subscriptions" class="dropdown-item">
              <i class="ri-vip-crown-line"></i> Manage Subscription
            </router-link>
            <router-link to="/buy-credits" class="dropdown-item">
              <i class="ri-shopping-cart-line"></i> Buy Credits
            </router-link>
            <a href="mailto:support@profilebakery.com" class="dropdown-item" target="_blank" rel="noopener noreferrer" @click.stop>
              <i class="ri-customer-service-line"></i> Support
            </a>
            <button @click="handleLogout" class="dropdown-item" :disabled="isLoggingOut">
              <i class="ri-logout-box-r-line"></i> {{ isLoggingOut ? 'Logging out...' : 'Logout' }}
            </button>
          </div>
        </div>
      </div>
      <div v-else class="auth-buttons">
        <button @click="handleAuthClick" class="auth-button login-signup auth-exempt">
          <i class="ri-user-add-line"></i>
          <span>Login / Signup</span>
        </button>
      </div>
    </div>
    <div v-if="showDropdown" class="dropdown-overlay" @click="toggleDropdown"></div>
  </header>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '../store/auth';
import { useCreditsStore } from '../store/credits';
import { storeToRefs } from 'pinia';
import { inject } from 'vue';

export default {
  name: 'HeaderComponent',
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const creditsStore = useCreditsStore();
    
    const { user } = storeToRefs(authStore);
    const { credits } = storeToRefs(creditsStore);
    
    const showDropdown = ref(false);
    const isLoggingOut = ref(false);

    const displayName = computed(() => user.value?.user_metadata?.full_name || 'User');

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const handleLogout = async () => {
      if (isLoggingOut.value) return;

      isLoggingOut.value = true;
      try {
        await authStore.logout();
        router.push('/');
      } catch (error) {
        console.error('Logout failed:', error);
        // Optionally, show an error message to the user
      } finally {
        isLoggingOut.value = false;
        showDropdown.value = false;
      }
    };

    const goToBuyCredits = () => {
      if (user.value) {
        router.push('/buy-credits');
      } else {
        console.error('User not logged in');
        // Optionally, show a message to the user or redirect to login
      }
    };

    const openAuthModal = inject('openAuthModal');

    const handleAuthClick = (event) => {
      event.preventDefault(); // Prevent default action
      openAuthModal();
    };

    onMounted(async () => {
      if (user.value) {
        await creditsStore.fetchCredits(user.value.id);
      }
    });

    watch(user, async (newUser) => {
      if (newUser) {
        await creditsStore.fetchCredits(newUser.id);
      } else {
        creditsStore.resetCredits();
      }
    });

    return {
      user,
      displayName,
      credits,
      showDropdown,
      toggleDropdown,
      handleLogout,
      isLoggingOut,
      goToBuyCredits,
      openAuthModal,
      handleAuthClick,
    };
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: #1e2235;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.spacer {
  flex-grow: 1;
}

.actions {
  display: flex;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #3a3f5c;
  transition: border-color 0.3s ease;
}

.user-avatar:hover {
  border-color: #007bff;
}

.user-credits {
  margin-right: 1rem;
  font-weight: 500;
  color: #a0a5b9;
  display: flex;
  align-items: center;
}

.user-credits i {
  margin-right: 0.25rem;
}

.credits-low {
  color: #ff6b6b;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #2a2f45;
  border: 1px solid #3a3f5c;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 1100; /* Increased z-index for better stacking */
  min-width: 180px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  clear: both;
  font-weight: 400;
  color: #a0a5b9;
  text-align: left;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 0.9rem;
  text-decoration: none;
}

.dropdown-item i {
  margin-right: 0.5rem;
  font-size: 1.1rem;
}

.dropdown-item:hover {
  background-color: #3a3f5c;
  color: white;
}

.btn-buy-credits {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.btn-buy-credits i {
  margin-right: 0.25rem;
}

.btn-buy-credits:hover {
  background-color: #45a049;
}

.auth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3a3f5c;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.auth-button:hover {
  background-color: #4a5172;
}

.auth-button i {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .header {
    padding: 0.75rem 1rem;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .actions {
    width: auto;
    justify-content: space-between;
  }

  .user-credits {
    display: inline-block;
    margin-right: 0.5rem;
    font-size: 0.9rem;
  }

  .btn-buy-credits {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem; /* Reduce font size for mobile */
  }

  .auth-button {
    padding: 0.5rem;
  }

  .auth-button span {
    display: none;
  }

  .user-menu {
    position: static;
  }

  .dropdown-menu {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
    max-height: 70vh;
    overflow-y: auto;
    z-index: 1100; /* Ensure this is higher than the sidebar's z-index */
  }
}

.dropdown-item.greeting {
  font-weight: 500;
  color: white;
  cursor: default;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #3a3f5c;
}

.dropdown-item.greeting:hover {
  background-color: transparent;
}

.dropdown-item:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.feedback-btn {
  display: flex;
  align-items: center;
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 500;
  text-decoration: none;
  margin-right: 1rem;
}

.feedback-btn:hover {
  background-color: #218838;
}

.feedback-btn i {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .feedback-btn {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }

  .feedback-btn span {
    display: none;
  }
}

.dropdown-item.greeting {
  font-weight: 500;
  color: white;
  cursor: default;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #3a3f5c;
}

.dropdown-item.greeting:hover {
  background-color: transparent;
}

.dropdown-item:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1099; /* Just below the dropdown menu */
  display: none;
}

@media (max-width: 768px) {
  .dropdown-overlay {
    display: block;
  }
}
</style>