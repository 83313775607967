import { ref } from 'vue'
import { supabase } from '../supabase'
import { loadStripe } from '@stripe/stripe-js'

const stripeCustomerId = ref(null)
const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)

export function useStripe() {
  const setStripeCustomerId = (id) => {
    stripeCustomerId.value = id
  }

  const getStripeCustomerId = async (userId) => {
    if (stripeCustomerId.value) return stripeCustomerId.value

    try {
      const { data, error } = await supabase
        .from('user_credits')
        .select('stripe_customer_id')
        .eq('user_id', userId)
        .single();

      if (error) throw error;
      if (!data || !data.stripe_customer_id) {
        console.error('No Stripe customer ID found for user:', userId);
        return null;
      }
      setStripeCustomerId(data.stripe_customer_id);
      return data.stripe_customer_id;
    } catch (error) {
      console.error('Error fetching Stripe customer ID:', error);
      return null;
    }
  }

  const createCheckoutSession = async (plan, credits, userId, customerEmail) => {
    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ plan, credits, userId, customerEmail }),
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const { id: sessionId, url } = await response.json();
      return { sessionId, url };
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  }

  return {
    stripeCustomerId,
    stripePromise,
    setStripeCustomerId,
    getStripeCustomerId,
    createCheckoutSession
  }
}