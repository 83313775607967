<template>
  <div class="face-improve">
    <div class="image-container">
      <!-- Input Face Column -->
      <div class="image-box">
        <h3>1. A closeup image of your face</h3>
        <div 
          class="dropzone"
          @click="triggerFileInput('input')"
          @dragover.prevent
          @drop.prevent="handleDrop('input', $event)"
        >
          <img v-if="inputFace" :src="inputFace" alt="Input Face" />
          <div v-else class="placeholder">
            <i class="mdi mdi-upload"></i>
            <span>Click or Drag-n-Drop to Upload</span>
          </div>
        </div>
        <input type="file" ref="inputFileInput" @change="onFileChange('input', $event)" style="display: none;" />
        <div class="actions">
          <button class="delete-btn" @click.stop="clearImage('input')" v-if="inputFace">
            <i class="mdi mdi-delete"></i> Clear
          </button>
        </div>
      </div>
      
      <!-- Target Face Column -->
      <div class="image-box">
        <h3>2. An image that doesn't look like you</h3>
        <div 
          class="dropzone"
          @click="triggerFileInput('target')"
          @dragover.prevent
          @drop.prevent="handleDrop('target', $event)"
        >
          <img v-if="targetFace" :src="targetFace" alt="Target Face" />
          <div v-else class="placeholder">
            <i class="mdi mdi-upload"></i>
            <span>Click or Drag-n-Drop to Upload</span>
          </div>
        </div>
        <input type="file" ref="targetFileInput" @change="onFileChange('target', $event)" style="display: none;" />
        <div class="actions">
          <button class="delete-btn" @click.stop="clearImage('target')" v-if="targetFace">
            <i class="mdi mdi-delete"></i> Clear
          </button>
        </div>
      </div>

      <!-- Result Image Column -->
      <div class="image-box">
        <h3>3. Result</h3>
        <div class="dropzone result-zone">
          <img v-if="resultImage" :src="resultImage" alt="Result Image" />
          <div v-else-if="isLoading" class="placeholder loading">
            <div class="spinner"></div>
            <span>Generating image...</span>
          </div>
          <div v-else-if="error" class="placeholder error">
            <span>😕 {{ error }}</span>
            <button v-if="showRechargeButton" @click="goToBuyCredits" class="recharge-btn">Recharge Credits</button>
          </div>
          <div v-else class="placeholder">
            <span>Generated image will appear here</span>
          </div>
        </div>
        <div class="actions" v-if="resultImage">
          <button class="download-btn" @click="downloadImage">
            <i class="mdi mdi-download"></i> Download
          </button>
        </div>
      </div>
    </div>
    
    <button @click="generateImage" :disabled="!inputFace || !targetFace || isLoading" class="generate-btn">
      {{ isLoading ? 'Generating...may take up to 1 minute' : 'Improve Face (1 Credit)' }}
    </button>

    <div class="tip-container">
      <!-- ... existing tip content ... -->
    </div>

    <p v-if="error" class="error-message">{{ error }}</p>

    <!-- Example Images Section -->
    <div class="example-section">
      <h3>Examples</h3>
      <div class="example-container">
        <div class="example-item">
          <h4>Input Face:</h4>
          <img src="/Images/examples/faceimprove-input.webp" alt="Example input face" class="example-image">
        </div>
        <div class="example-item">
          <h4>Target Face:</h4>
          <img src="/Images/examples/faceimprove-target.webp" alt="Example target face" class="example-image">
        </div>
        <div class="example-item">
          <h4>Result:</h4>
          <img src="/Images/examples/faceimprove-result.jpg" alt="Example result image" class="example-image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
import { supabase } from '../supabase';
import { useCreditsStore } from '../store/credits';

export default {
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      inputFace: null,
      targetFace: null,
      resultImage: null,
      inputBase64: null,
      targetBase64: null,
      error: null,
      isLoading: false,
      userId: null,
      showRechargeButton: false
    };
  },
  async created() {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      this.userId = user.id;
    }
  },
  methods: {
    triggerFileInput(type) {
      this.$refs[`${type}FileInput`].click();
    },
    onFileChange(type, event) {
      const file = event.target.files[0];
      this[`${type}Face`] = URL.createObjectURL(file);
      this.convertToBase64(file, type);
    },
    handleDrop(type, event) {
      const file = event.dataTransfer.files[0];
      this[`${type}Face`] = URL.createObjectURL(file);
      this.convertToBase64(file, type);
    },
    clearImage(type) {
      this[`${type}Face`] = null;
      this[`${type}Base64`] = null;
      this.resultImage = null;
      this.error = null;
    },
    async convertToBase64(file, type) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this[`${type}Base64`] = reader.result.split(',')[1];
      };
      reader.readAsDataURL(file);
    },
    async generateImage() {
      if (!this.inputFace || !this.targetFace) {
        this.error = '🙈 Oops! Please upload both input and target faces before generating.';
        this.showRechargeButton = false;
        return;
      }

      const creditsStore = useCreditsStore();
      await creditsStore.fetchCredits(this.userId);
      const userCredits = creditsStore.credits;

      if (userCredits < 1) {
        this.error = '💸 Oh no! You need more credits to generate an image.';
        this.showRechargeButton = true;
        return;
      }

      this.showRechargeButton = false;
      this.isLoading = true;
      this.resultImage = null;
      this.error = null;

      try {
        const hostname = window.location.hostname;
        let apiUrl;

        if (hostname === 'localhost') {
          apiUrl = 'http://localhost:3000/api/faceImprove';
        } else if (hostname === 'staging.app.profilebakery.com') {
          apiUrl = 'https://staging.app.profilebakery.com/api/faceImprove';
        } else {
          apiUrl = 'https://new.app.profilebakery.com/api/faceImprove';
        }

        const payload = {
          source_img: this.inputBase64,
          target_img: this.targetBase64,
          user_id: this.userId
        };

        const response = await axios.post(apiUrl, payload, {
          responseType: 'json',
          timeout: 300000 // 5 minutes timeout
        });

        if (response.data && response.data.image) {
          this.resultImage = `data:image/jpeg;base64,${response.data.image}`;
          // Deduct credits only if the image generation was successful
          await this.deductCredits();
        } else {
          throw new Error('Unexpected API response format');
        }
      } catch (error) {
        console.error('Error:', error);
        if (error.response) {
          console.error('Error status:', error.response.status);
          if (error.response.status === 504) {
            this.error = '⏳ The request timed out. The image might be too large or the server is busy. Please try again later.';
          } else {
            this.error = `🚫 An error occurred (Status ${error.response.status}). Please try again.`;
          }
        } else if (error.request) {
          this.error = '🌐 No response received from the server. Please check your internet connection and try again.';
        } else {
          this.error = `🚫 An error occurred: ${error.message}`;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async deductCredits() {
      const creditsStore = useCreditsStore();
      try {
        await creditsStore.deductCredits(this.userId, 1);
        console.log('Credits deducted successfully');
      } catch (error) {
        console.error('Error deducting credits:', error);
        this.error = 'Failed to deduct credits. Please try again or contact support.';
      }
    },
    downloadImage() {
      if (this.resultImage) {
        const link = document.createElement('a');
        link.href = this.resultImage;
        link.download = 'face_swap_result.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    goToBuyCredits() {
      this.$router.push('/buy-credits');
    }
  }
};
</script>
<style scoped>
.face-improve {
  max-width: 100%;
  padding: 1rem;
  transition: all 0.3s;
  box-sizing: border-box;
}

.image-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.image-box {
  flex: 1;
  min-width: 0; /* This allows the box to shrink below its content size */
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

h3 {
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
  font-size: 1.2rem;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 1rem;
  cursor: pointer;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.dropzone:hover {
  border-color: #007bff;
  background-color: #f0f8ff;
}

.dropzone img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: auto;
  height: auto;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #6c757d;
}

.placeholder i {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.placeholder span {
  font-size: 1rem;
}

.actions {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.delete-btn, .download-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-btn {
  background-color: #007bff;
}

.delete-btn:hover, .download-btn:hover {
  opacity: 0.8;
}

.generate-btn {
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.generate-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.generate-btn:hover:not(:disabled) {
  background-color: #218838;
}

.error-message {
  color: #dc3545;
  margin-top: 1rem;
  text-align: center;
}

.placeholder.error {
  color: #dc3545;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message a {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.recharge-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.recharge-btn:hover {
  background-color: #0056b3;
}


.tip-container {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #e9f5ff;
  border-radius: 8px;
  border-left: 4px solid #007bff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tip-text {
  color: #333;
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0;
  flex: 1;
  margin-right: 20px;
}

.tip-link {
  color: #007bff;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.tip-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.aspect-ratio-guide {
  width: 75px;
  height: 100px;
  border: 2px dashed #007bff;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.guide-box {
  width: 60px;
  height: 80px;
  background-color: rgba(0, 123, 255, 0.1);
}

.warning-message {
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.dropzone.has-warning {
  border-color: #ffc107;
}

/* Responsive adjustments */
@media (max-width: 1400px) {
  .face-improve {
    padding: 1.5rem;
  }
}

@media (max-width: 1200px) {
  .image-container {
    flex-wrap: wrap;
  }

  .image-box {
    flex-basis: calc(50% - 0.5rem);
  }

  .image-box:last-child {
    flex-basis: 100%;
    margin-top: 1rem;
  }
}

@media (max-width: 768px) {
  .face-improve {
    padding: 1rem;
  }

  .image-container {
    flex-direction: column;
  }

  .image-box {
    width: 100%;
    flex-basis: 100%;
    margin-bottom: 1rem;
  }

  .image-box:last-child {
    margin-top: 0;
  }

  .dropzone {
    height: 180px;
  }

  .generate-btn {
    font-size: 0.9rem;
    padding: 0.5rem;
    width: 100%;
    margin: 0.5rem 0;
  }
}

@media (max-width: 480px) {
  .face-improve {
    padding: 0.5rem;
  }

  .image-box {
    padding: 0.25rem;
  }

  .dropzone {
    height: 150px;
  }

  h3 {
    font-size: 0.9rem;
  }

  .placeholder i {
    font-size: 2rem;
  }

  .placeholder span {
    font-size: 0.8rem;
  }

  .generate-btn {
    width: 100%;
    font-size: 0.8rem;
    margin: 0.25rem 0;
  }
}

.example-section {
  margin-top: 2rem;
}

.example-section h3 {
  text-align: center;
  margin-bottom: 1rem;
}

.example-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.example-item {
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.example-item h4 {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.example-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

@media (max-width: 768px) {
  .example-container {
    flex-direction: column;
  }

  .example-item {
    margin-bottom: 1rem;
  }
}
</style>