<template>
  <div class="light-change">
    <div class="image-container">
      <!-- Input Image Column -->
      <div class="column">
        <h3>1. Input Image</h3>
        <div 
          class="dropzone"
          @click="triggerFileInput"
          @dragover.prevent
          @drop.prevent="handleDrop"
        >
          <img v-if="inputImage" :src="inputImage" alt="Input Image" />
          <div v-else class="placeholder">
            <i class="mdi mdi-upload"></i>
            <span>Click or Drag-n-Drop to Upload</span>
          </div>
        </div>
        <input type="file" ref="fileInput" @change="onFileChange" accept="image/*" style="display: none;" />
        <div class="actions">
          <button class="delete-btn" @click.stop="clearImage" v-if="inputImage">
            <i class="mdi mdi-delete"></i> Clear
          </button>
        </div>
      </div>
      
      <!-- Prompt Column -->
      <div class="column">
        <h3>2. Enter Prompt</h3>
        <div class="input-group">
          <input type="text" id="prompt" v-model="prompt" placeholder="Enter your prompt here">
        </div>
        
        <!-- Text Snippets -->
        <div class="text-snippets">
          <button 
            v-for="snippet in textSnippets" 
            :key="snippet" 
            @click="addSnippet(snippet)" 
            class="snippet-btn"
          >
            {{ snippet }}
          </button>
        </div>

        <button @click="generateImage" :disabled="!inputImage || isLoading" class="generate-btn">
          {{ isLoading ? 'Generating...takes about 30s' : 'Generate (3 Credits)' }}
        </button>
      </div>
      
      <!-- Result Image Column -->
      <div class="column">
        <h3>3. Result Image</h3>
        <div class="dropzone result-zone">
          <img v-if="resultImage" :src="resultImage" alt="Result Image" />
          <div v-else-if="isLoading" class="placeholder loading">
            <div class="spinner"></div>
            <span>Generating image...may take up to 30 seconds</span>
          </div>
          <div v-else-if="error" class="placeholder error">
            <span>😕 {{ error }}</span>
            <button v-if="showRechargeButton" @click="goToBuyCredits" class="recharge-btn">Recharge Credits</button>
          </div>
          <div v-else class="placeholder">
            <span>Generated image will appear here</span>
          </div>
        </div>
        <div class="actions" v-if="resultImage">
          <button class="download-btn" @click="downloadImage">
            <i class="mdi mdi-download"></i> Download
          </button>
        </div>
      </div>
    </div>
  
    <p v-if="error" class="error-message" v-html="error"></p>

    <!-- Example Images Section -->
    <div class="example-section">
      <h3>Examples</h3>
      <div class="example-container">
        <div class="example-item">
          <h4>Input Image:</h4>
          <img src="/Images/examples/faceimprove-target.webp" alt="Example input image" class="example-image">
        </div>
        <div class="example-item">
          <h4>Prompt:</h4>
          <p class="example-prompt">"Blurry office background"</p>
        </div>
        <div class="example-item">
          <h4>Result:</h4>
          <img src="/Images/examples/light_changed_image.jpg" alt="Example result image" class="example-image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
import { supabase } from '../supabase';
import { useCreditsStore } from '../store/credits';

export default {
  name: 'LightChangeView',
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      inputImage: null,
      resultImage: null,
      base64Image: null,
      prompt: '',
      error: null,
      isLoading: false,
      userId: null,
      showRechargeButton: false,
      textSnippets: [
        "Blurry office background",
        "blurry new york background",
        "city neon light",
        "soft studio light"
      ]
    };
  },
  async created() {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      this.userId = user.id;
    }
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.handleFile(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.handleFile(file);
    },
    async handleFile(file) {
      if (file && file.type.startsWith('image/')) {
        this.inputImage = URL.createObjectURL(file);
        try {
          this.base64Image = await this.convertToBase64(file);
        } catch (error) {
          console.error('Error converting image to base64:', error);
          this.error = '😕 Error processing the image. Please try again.';
        }
      }
    },
    clearImage() {
      this.inputImage = null;
      this.resultImage = null;
      this.base64Image = null;
      this.error = null;
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(',')[1];
          resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async generateImage() {
      if (!this.inputImage || !this.prompt) {
        this.error = '🙈 Oops! Please upload an image and enter a prompt before generating.';
        this.showRechargeButton = false;
        return;
      }

      const creditsStore = useCreditsStore();
      await creditsStore.fetchCredits(this.userId);
      const userCredits = creditsStore.credits;

      if (userCredits < 3) {
        this.error = '💸 Oh no! You need more credits to generate an image.';
        this.showRechargeButton = true;
        return;
      }

      this.showRechargeButton = false;
      this.isLoading = true;
      this.resultImage = null;
      this.error = null;

      const hostname = window.location.hostname;
      let apiUrl;

      if (hostname === 'localhost') {
        apiUrl = 'http://localhost:3000/api/lightChange';
      } else if (hostname === 'staging.app.profilebakery.com') {
        apiUrl = 'https://staging.app.profilebakery.com/api/lightChange';
      } else {
        apiUrl = 'https://new.app.profilebakery.com/api/lightChange';
      }

      try {
        const response = await axios.post(apiUrl, {
          subject_image: this.base64Image,
          prompt: this.prompt,
          user_id: this.userId
        }, {
          timeout: 120000 // Set timeout to 2 minutes (120000 ms)
        });

        if (response.data && response.data.output) {
          this.resultImage = response.data.output;
          await this.deductCredits();
        } else {
          throw new Error('Unexpected API response format');
        }
      } catch (error) {
        console.error('Detailed error:', error);
        console.error('Error response:', error.response?.data);
        if (error.code === 'ECONNABORTED') {
          this.error = '⏳ The request timed out. The server might be busy. Please try again later.';
        } else {
          this.error = `🚫 Oops! Something went wrong while generating the image. Error: ${error.response?.data?.message || error.message}`;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async deductCredits() {
      const creditsStore = useCreditsStore();
      try {
        await creditsStore.deductCredits(this.userId, 3);
      } catch (error) {
        console.error('Error deducting credits:', error);
        this.error = '😅 Image generated successfully, but there was an error deducting credits. Please contact support.';
      }
    },
    async downloadImage() {
      if (this.resultImage) {
        try {
          // Fetch the image as a blob
          const response = await fetch(this.resultImage);
          const blob = await response.blob();

          // Create a blob URL
          const blobUrl = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = 'light_changed_image.jpg';

          // Append to the document, click, and remove
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Release the blob URL
          window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
          console.error('Error downloading image:', error);
          this.error = 'Failed to download the image. Please try again.';
        }
      }
    },
    addSnippet(snippet) {
      if (this.prompt) {
        this.prompt += ', ' + snippet;
      } else {
        this.prompt = snippet;
      }
    },
    goToBuyCredits() {
      this.$router.push('/buy-credits');
    }
  }
};
</script>

<style scoped>
.light-change {
  max-width: 100%;
  padding: 1rem;
  transition: all 0.3s;
  box-sizing: border-box;
}

.image-container {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.column {
  flex: 1;
  min-width: 0; /* This allows the box to shrink below its content size */
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

h3 {
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 1rem;
  cursor: pointer;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.result-zone {
  cursor: default;
}

.dropzone img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: auto;
  height: auto;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #888;
}

.placeholder i {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.placeholder span {
  font-size: 1.2rem;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.delete-btn, .download-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-btn {
  background-color: #007bff;
}

.delete-btn:hover, .download-btn:hover {
  opacity: 0.8;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.text-snippets {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.snippet-btn {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 0.25rem 0.75rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.snippet-btn:hover {
  background-color: #e0e0e0;
}

.generate-btn {
  width: 100%;
  margin-top: auto;
  padding: 0.75rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.generate-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.generate-btn:hover:not(:disabled) {
  background-color: #218838;
}

.error-message {
  color: #dc3545;
  margin-top: 1rem;
  text-align: center;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.error-message a {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.recharge-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.recharge-btn:hover {
  background-color: #0056b3;
}

@media (max-width: 1400px) {
  .light-change {
    padding: 1.5rem;
  }
}

@media (max-width: 1200px) {
  .image-container {
    flex-wrap: wrap;
  }

  .column {
    flex-basis: calc(50% - 0.75rem);
  }

  .column:last-child {
    flex-basis: 100%;
    margin-top: 1.5rem;
  }
}

@media (max-width: 768px) {
  .light-change {
    padding: 1rem;
  }

  .image-container {
    flex-direction: column;
  }

  .column {
    width: 100%;
    flex-basis: 100%;
    margin-bottom: 1.5rem;
  }

  .column:last-child {
    margin-top: 0;
  }

  .generate-btn {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  .light-change {
    padding: 0.5rem;
  }

  .column {
    padding: 0.5rem;
  }

  .dropzone {
    height: 150px;
  }

  h3 {
    font-size: 1rem;
  }

  .placeholder i {
    font-size: 2rem;
  }

  .placeholder span {
    font-size: 0.8rem;
  }

  .generate-btn {
    font-size: 0.8rem;
  }
}

.example-section {
  margin-top: 2rem;
}

.example-section h3 {
  text-align: center;
  margin-bottom: 1rem;
}

.example-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.example-item {
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.example-item h4 {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.example-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.example-prompt {
  font-style: italic;
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .example-container {
    flex-direction: column;
  }

  .example-item {
    margin-bottom: 1rem;
  }
}
</style>