<template>
    <div class="success-view">
      <HeaderComponent />
      <div class="success-content">
        <div class="emoji">🎉</div>
        <h1>Payment Successful!</h1>
        <p>Thank you for your purchase. Your credits have been added to your account.</p>
        <button @click="returnHome" class="home-button">Return to Home</button>
      </div>
    </div>
  </template>

  <style scoped>
  .success-view {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .success-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
  }

  .emoji {
    font-size: 6rem;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 2.5rem;
    color: #28a745;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    color: #6c757d;
    max-width: 600px;
    margin-bottom: 2rem;
  }

  .home-button {
    display: inline-block;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }

  .home-button:hover {
    background-color: #0056b3;
  }
  </style>

  <script>
  import { useRouter } from 'vue-router';

  export default {
    setup() {
      const router = useRouter();

      const returnHome = () => {
        router.push('/');
        setTimeout(() => {
          window.location.reload();
        }, 100);
      };

      return {
        returnHome
      };
    }
  };
  </script>