<template>
  <div>
    <h1>Confirming your email...</h1>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/store/auth' // Correct import

export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { confirmEmail, login } = useAuthStore()
    const error = ref(null)

    onMounted(async () => {
      const token = route.query.token
      const email = route.query.email

      if (!token || !email) {
        error.value = 'Invalid confirmation link'
        return
      }

      try {
        await confirmEmail(token, email)
        // Automatically log in the user
        await login(email, '') // You might need to handle this differently
        router.push('/') // Redirect to home or dashboard
      } catch (err) {
        error.value = err.message
      }
    })

    return { error }
  }
}
</script>
