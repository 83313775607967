<template>
  <div class="auth-container">
    <h2 class="auth-title">Welcome Back</h2>
    <p class="auth-subtitle">Log in to your account to continue</p>
    
    <form @submit.prevent="handleLogin" class="auth-form">
      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" v-model="email" type="email" placeholder="you@example.com" required>
        <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input id="password" v-model="password" type="password" placeholder="••••••••" required>
        <span v-if="errors.password" class="error-message">{{ errors.password }}</span>
      </div>
      <div class="remember-me">
        <input type="checkbox" id="remember-me" v-model="rememberMe">
        <label for="remember-me">Remember me</label>
      </div>
      <span v-if="errors.general" class="error-message">{{ errors.general }}</span>
      <button type="submit" class="btn btn-primary" :disabled="isLoading">
        {{ isLoading ? 'Logging in...' : 'Log In' }}
      </button>
    </form>

    <div class="auth-footer">
      <p>Don't have an account?</p>
      <button @click="switchToSignup" class="btn btn-secondary">Sign up</button>
      <a href="#" @click.prevent="switchToForgotPassword" class="forgot-password-link">Forgot password?</a>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { supabase } from '../supabase'

export default {
  name: 'LoginView',
  props: {
    close: Function,
    switchToSignup: Function,
    switchToForgotPassword: Function
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const email = ref('')
    const password = ref('')
    const rememberMe = ref(false)
    const isLoading = ref(false)
    const errors = ref({})

    const validateForm = () => {
      errors.value = {}
      if (!email.value) errors.value.email = 'Email is required'
      if (!password.value) errors.value.password = 'Password is required'
      return Object.keys(errors.value).length === 0
    }

    const handleLogin = async () => {
      if (!validateForm()) return

      isLoading.value = true
      errors.value = {} // Clear previous errors
      try {
        const { data, error } = await supabase.auth.signInWithPassword({
          email: email.value,
          password: password.value,
        })

        if (error) throw error

        if (rememberMe.value) {
          // Set a cookie to remember the user
          document.cookie = `rememberedUser=${data.user.id}; max-age=2592000; path=/; Secure; SameSite=Strict`
        }

        await store.dispatch('auth/setUser', data.user)
        props.close()
        router.push('/')
      } catch (error) {
        console.error('Login error:', error)
        errors.value.general = 'Invalid email or password. Please try again.'
      } finally {
        isLoading.value = false
      }
    }

    const forgotPassword = async () => {
      if (!email.value) {
        errors.value.email = 'Please enter your email address'
        return
      }

      try {
        const { error } = await supabase.auth.resetPasswordForEmail(email.value)
        if (error) throw error
        alert('Password reset email sent. Please check your inbox.')
      } catch (error) {
        console.error('Password reset error:', error)
        errors.value.general = error.message || 'An error occurred. Please try again.'
      }
    }

    return { 
      email, 
      password, 
      rememberMe,
      isLoading,
      errors,
      handleLogin,
      forgotPassword
    }
  }
}
</script>

<style scoped>
.auth-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.auth-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.auth-subtitle {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.auth-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.btn {
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  color: #ffffff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #dc3545;
  color: #ffffff;
  margin-top: 1rem;
  margin-bottom: 15px;  /* Add this line */
}

.btn-secondary:hover {
  background-color: #c82333;
}

.auth-footer {
  margin-top: 1rem;
  text-align: center;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 0.2em;
}

.remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.remember-me input[type="checkbox"] {
  width: auto;
  margin-right: 0.5rem;
}

.remember-me label {
  margin-bottom: 0;
  font-size: 0.9rem;
  color: #666;
}
</style>