import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { supabase } from '../supabase'
import { useCreditsStore } from './credits'

export const useAuthStore = defineStore('auth', () => {
  const user = ref(null)
  const showAuthModal = ref(false)
  const isLoading = ref(false)

  const isAuthenticated = computed(() => !!user.value)

  const setUser = (newUser) => {
    user.value = newUser
  }

  const login = async (email, password) => {
    isLoading.value = true
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ email, password })
      if (error) throw error
      setUser(data.user)
      return { user: data.user }
    } finally {
      isLoading.value = false
    }
  }

  const loginWithGoogle = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google'
    })
    if (error) throw error
    return data
  }

  const loginWithFacebook = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'facebook'
    })
    if (error) throw error
    return data
  }

  const logout = async () => {
    const { error } = await supabase.auth.signOut()
    if (error) throw error
    setUser(null)
    const creditsStore = useCreditsStore()
    creditsStore.resetCredits()
  }

  const openAuthModal = () => {
    showAuthModal.value = true
  }

  const initializeAuth = async () => {
    isLoading.value = true
    try {
      const { data, error } = await supabase.auth.getSession()
      if (error) throw error
      setUser(data?.session?.user || null)

      supabase.auth.onAuthStateChange((event, session) => {
        setUser(session?.user || null)
        if (session?.user) {
          const creditsStore = useCreditsStore()
          creditsStore.fetchCredits(session.user.id)
        }
      })
    } catch (error) {
      console.error('Error in initializeAuth:', error)
    } finally {
      isLoading.value = false
    }
  }

  const signup = async (email, password, name) => {
    isLoading.value = true
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            full_name: name
          }
        }
      })
      if (error) throw error
      return { user: data.user }
    } finally {
      isLoading.value = false
    }
  }

  const resetPasswordForEmail = async (email) => {
    isLoading.value = true
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      })
      if (error) throw error
    } finally {
      isLoading.value = false
    }
  }

  const resetPassword = async (accessToken, newPassword) => {
    isLoading.value = true
    try {
      const { error } = await supabase.auth.updateUser(
        { password: newPassword },
        { accessToken }
      )
      if (error) throw error
    } finally {
      isLoading.value = false
    }
  }

  return {
    user,
    isAuthenticated,
    isLoading,
    setUser,
    login,
    loginWithGoogle,
    loginWithFacebook,
    logout,
    initializeAuth,
    signup,
    showAuthModal,
    openAuthModal,
    resetPasswordForEmail,
    resetPassword,
  }
})
