<template>
    <div v-if="error">
      <h2>An error occurred</h2>
      <p>{{ error.message }}</p>
    </div>
    <slot v-else></slot>
  </template>
  
  <script>
  export default {
    name: 'ErrorBoundary',
    data() {
      return {
        error: null
      }
    },
    errorCaptured(err, vm, info) {
      this.error = err
      console.error('Error captured by boundary:', err, vm, info)
      return false // Prevent the error from propagating further
    }
  }
  </script>