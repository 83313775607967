<template>
    <div class="register">
      <h2>Register</h2>
      <form @submit.prevent="handleRegister">
        <div>
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="email" required>
        </div>
        <div>
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password" required>
        </div>
        <button type="submit">Register</button>
      </form>
    </div>
  </template>
  
  <script>
  import { ref, inject } from 'vue'
  import { useRouter } from 'vue-router'

  export default {
    setup() {
      const state = inject('state')
      const router = useRouter()
      const email = ref('')
      const password = ref('')

      const handleRegister = async () => {
        try {
          // Implement actual registration logic here
          state.user = { email: email.value, avatar: '/default-avatar.png' }
          router.push('/')
        } catch (error) {
          console.error('Registration failed:', error)
        }
      }

      return {
        email,
        password,
        handleRegister
      }
    }
  }
  </script>
  
  <style scoped>
  .register {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
  }

  form > div {
    margin-bottom: 1rem;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
  }

  input {
    width: 100%;
    padding: 0.5rem;
  }

  button {
    display: block;
    width: 100%;
    padding: 0.5rem;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
  }
  </style>