<template>
  <div class="change-clothes" :class="{ 'sidebar-collapsed': isSidebarCollapsed }">
    <div class="image-container">
      <!-- Input Image Column -->
      <div class="image-box">
        <h3>1. Upload an Image</h3>
        <div 
          class="dropzone"
          @click="triggerFileInput('person')"
          @dragover.prevent
          @drop.prevent="handleDrop('person', $event)"
        >
          <img v-if="personImage" :src="personImage" alt="Person Image" />
          <div v-else class="placeholder">
            <i class="mdi mdi-upload"></i>
            <span>Click or Drag-n-Drop to Upload</span>
          </div>
        </div>
        <input 
          type="file" 
          ref="personFileInput"
          @change="handleFileSelect('person', $event)" 
          accept="image/*" 
          style="display: none;"
        />
        <div class="actions">
          <button class="delete-btn" @click.stop="clearImage('person')" v-if="personImage">
            <i class="mdi mdi-delete"></i> Clear
          </button>
        </div>
        <div v-if="personWarning" class="warning-message">{{ personWarning }}</div>
      </div>
      
      <!-- Garment Image Column -->
      <div class="image-box">
        <h3>2. Upload a Garment Image</h3>
        <div 
          class="dropzone"
          @click="triggerFileInput('garment')"
          @dragover.prevent
          @drop.prevent="handleDrop('garment', $event)"
        >
          <img v-if="garmentImage" :src="garmentImage" alt="Garment Image" />
          <div v-else class="placeholder">
            <i class="mdi mdi-upload"></i>
            <span>Click or Drag-n-Drop to Upload</span>
          </div>
        </div>
        <input 
          type="file" 
          ref="garmentFileInput"
          @change="handleFileSelect('garment', $event)" 
          accept="image/*" 
          style="display: none;"
        />
        <div class="actions">
          <button class="delete-btn" @click.stop="clearImage('garment')" v-if="garmentImage">
            <i class="mdi mdi-delete"></i> Clear
          </button>
        </div>
        <div v-if="garmentWarning" class="warning-message">{{ garmentWarning }}</div>
      </div>

      <!-- Result Image Column -->
      <div class="image-box">
        <h3>3. Result</h3>
        <div class="dropzone result-zone">
          <img v-if="result" :src="result" alt="Try-On Result" />
          <div v-else-if="isLoading" class="placeholder loading">
            <div class="spinner"></div>
            <span>Generating image...</span>
          </div>
          <div v-else-if="error" class="placeholder error">
            <span>😕 {{ error }}</span>
            <button v-if="showRechargeButton" @click="goToBuyCredits" class="recharge-btn">Recharge Credits</button>
          </div>
          <div v-else class="placeholder">
            <span>Generated image will appear here</span>
          </div>
        </div>
        <div class="actions" v-if="result">
          <button class="download-btn" @click="downloadImage">
            <i class="mdi mdi-download"></i> Download
          </button>
        </div>
      </div>
    </div>

    <button @click="generateTryOn" :disabled="!personImage || !garmentImage || isLoading" class="generate-btn">
      {{ isLoading ? 'Generating...may take up to 1 minute' : 'Try on clothes (3 Credits)' }}
    </button>

    <div class="tip-container">
      <p class="tip-text">
        <i class="mdi mdi-information"></i>
        <strong>Important:</strong> Use images with a 3:4 ratio for best results. 
        <a href="https://www.birme.net/?target_height=682" target="_blank" rel="noopener noreferrer" class="tip-link">
          Resize your images here
        </a>
      </p>
      <div class="aspect-ratio-guide">
        <div class="guide-box"></div>
      </div>
    </div>

    <p v-if="error" class="error-message">{{ error }}</p>

    <!-- Example Images Section -->
    <div class="example-section">
      <h3>Examples</h3>
      <div class="example-container">
        <div class="example-item">
          <h4>Input Image:</h4>
          <img src="/Images/examples/changecloth-input.jpg" alt="Example input image" class="example-image">
        </div>
        <div class="example-item">
          <h4>Garment Image:</h4>
          <img src="/Images/examples/changecloth-garment.webp" alt="Example garment image" class="example-image">
        </div>
        <div class="example-item">
          <h4>Result:</h4>
          <img src="/Images/examples/changeclothes-result.png" alt="Example result image" class="example-image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { supabase } from '../supabase';
import { useCreditsStore } from '../store/credits';
import { useAuthStore } from '../store/auth';

export default {
  name: 'ChangeClothes',
  props: {
    isSidebarCollapsed: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const isAuthenticated = computed(() => !!authStore.user);
    const personImage = ref(null);
    const garmentImage = ref(null);
    const result = ref(null);
    const isLoading = ref(false);
    const error = ref(null);
    const personFileInput = ref(null);
    const garmentFileInput = ref(null);
    const personWarning = ref(null);
    const garmentWarning = ref(null);
    const userId = ref(null);
    const showRechargeButton = ref(false);

    onMounted(async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        userId.value = user.id;
      }
    });

    const triggerFileInput = (type) => {
      if (type === 'person') {
        personFileInput.value.click();
      } else {
        garmentFileInput.value.click();
      }
    };

    const handleFileSelect = (type, event) => {
      const file = event.target.files[0];
      handleFile(type, file);
    };

    const handleDrop = (type, event) => {
      const file = event.dataTransfer.files[0];
      handleFile(type, file);
    };

    const handleFile = (type, file) => {
      if (file && file.type.startsWith('image/')) {
        const img = new Image();
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          if (Math.abs(aspectRatio - 0.75) > 0.1) {
            if (type === 'person') {
              personWarning.value = 'Warning: Image aspect ratio is not 3:4. This may affect the result.';
            } else {
              garmentWarning.value = 'Warning: Image aspect ratio is not 3:4. This may affect the result.';
            }
          } else {
            if (type === 'person') {
              personWarning.value = null;
            } else {
              garmentWarning.value = null;
            }
          }
        };
        img.src = URL.createObjectURL(file);
        
        if (type === 'person') {
          personImage.value = URL.createObjectURL(file);
        } else {
          garmentImage.value = URL.createObjectURL(file);
        }
      }
    };

    const clearImage = (type) => {
      if (type === 'person') {
        personImage.value = null;
        personWarning.value = null;
      } else {
        garmentImage.value = null;
        garmentWarning.value = null;
      }
      result.value = null;
      error.value = null;
    };

    const generateTryOn = async () => {
      if (!isAuthenticated.value) {
        authStore.openAuthModal();
        return;
      }

      if (!personImage.value || !garmentImage.value) {
        error.value = '🙈 Oops! Please upload both person and garment images before generating.';
        showRechargeButton.value = false;
        return;
      }

      const creditsStore = useCreditsStore();
      await creditsStore.fetchCredits(userId.value);
      const userCredits = creditsStore.credits;

      if (userCredits < 3) {
        error.value = '💸 Oh no! You need more credits to generate an image.';
        showRechargeButton.value = true;
        return;
      }

      showRechargeButton.value = false;
      isLoading.value = true;
      result.value = null;
      error.value = null;

      try {
        const personBlob = await fetch(personImage.value).then(r => r.blob());
        const garmentBlob = await fetch(garmentImage.value).then(r => r.blob());

        const personBase64 = await blobToBase64(personBlob);
        const garmentBase64 = await blobToBase64(garmentBlob);

        const hostname = window.location.hostname;
        let apiUrl;

        if (hostname === 'localhost') {
          apiUrl = 'http://localhost:3000/api/changeClothes';
        } else if (hostname === 'staging.app.profilebakery.com') {
          apiUrl = 'https://staging.app.profilebakery.com/api/changeClothes';
        } else {
          apiUrl = 'https://new.app.profilebakery.com/api/changeClothes';
        }

        const response = await axios.post(apiUrl, {
          personImageBase64: personBase64,
          garmentImageBase64: garmentBase64,
          user_id: userId.value
        }, {
          headers: {
            'Content-Type': 'application/json'
          },
          responseType: 'arraybuffer',
          timeout: 300000 // 5 minutes timeout
        });

        const blob = new Blob([response.data], { type: 'image/png' });
        result.value = URL.createObjectURL(blob);

        // Refresh user's credit balance after successful operation
        await creditsStore.fetchCredits(userId.value);
      } catch (err) {
        console.error('Error:', err);
        if (err.response) {
          console.error('Error status:', err.response.status);
          if (err.response.status === 504) {
            error.value = '⏳ The request timed out. The image might be too large or the server is busy. Please try again later.';
          } else {
            error.value = `🚫 An error occurred (Status ${err.response.status}). Please try again.`;
          }
        } else if (err.request) {
          error.value = '🌐 No response received from the server. Please check your internet connection and try again.';
        } else {
          error.value = `🚫 An error occurred: ${err.message}`;
        }
      } finally {
        isLoading.value = false;
      }
    };

    const downloadImage = () => {
      if (result.value) {
        const link = document.createElement('a');
        link.href = result.value;
        link.download = 'try_on_result.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    const goToBuyCredits = () => {
      router.push('/buy-credits');
    };

    // Add this helper function
    const blobToBase64 = (blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    };

    return {
      personImage,
      garmentImage,
      result,
      isLoading,
      error,
      personFileInput,
      garmentFileInput,
      personWarning,
      garmentWarning,
      showRechargeButton,
      triggerFileInput,
      handleFileSelect,
      handleDrop,
      clearImage,
      generateTryOn,
      downloadImage,
      goToBuyCredits,
      isAuthenticated
    };
  }
};
</script>
<style scoped>
.change-clothes, .face-improve, .light-change {
  max-width: 100%;
  padding: 1rem;
  transition: all 0.3s;
  box-sizing: border-box;
}

.image-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.image-box, .column {
  flex: 1;
  min-width: 0; /* This allows the box to shrink below its content size */
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

@media (max-width: 1400px) {
  .change-clothes, .face-improve, .light-change {
    padding: 1.5rem;
  }
}

@media (max-width: 1200px) {
  .image-container {
    flex-wrap: wrap;
  }

  .image-box, .column {
    flex-basis: calc(50% - 0.5rem);
  }

  .image-box:last-child, .column:last-child {
    flex-basis: 100%;
    margin-top: 1rem;
  }
}

@media (max-width: 768px) {
  .change-clothes, .face-improve, .light-change {
    padding: 1rem;
  }

  .image-container {
    flex-direction: column;
  }

  .image-box, .column {
    width: 100%;
    flex-basis: 100%;
    margin-bottom: 1rem;
  }

  .image-box:last-child, .column:last-child {
    margin-top: 0;
  }

  .dropzone {
    height: 180px;
  }

  .generate-btn {
    font-size: 0.9rem;
    padding: 0.5rem;
    width: 100%;
    margin: 0.5rem 0;
  }
}

@media (max-width: 480px) {
  .change-clothes, .face-improve, .light-change {
    padding: 0.5rem;
  }

  .image-box, .column {
    padding: 0.25rem;
  }

  .dropzone {
    height: 150px;
  }

  h3 {
    font-size: 0.9rem;
  }

  .placeholder i {
    font-size: 2rem;
  }

  .placeholder span {
    font-size: 0.8rem;
  }

  .generate-btn {
    width: 100%;
    font-size: 0.8rem;
    margin: 0.25rem 0;
  }
}

h3 {
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
  font-size: 1.2rem;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 1rem;
  cursor: pointer;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.dropzone img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: auto;
  height: auto;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #ccc;
}

.placeholder i {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.placeholder span {
  font-size: 1.2rem;
}

.actions {
  margin-top: 1rem;
}

.delete-btn, .download-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.download-btn {
  background-color: #007bff;
}

.delete-btn i, .download-btn i {
  margin-right: 0.75rem;
  font-size: 1.2rem;
}

.generate-btn {
  display: block;
  width: 100%;
  padding: 1rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.generate-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.generate-btn:hover:not(:disabled) {
  background-color: #218838;
}

.error-message {
  color: #dc3545;
  margin-top: 1rem;
  text-align: center;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message a {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.recharge-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.recharge-btn:hover {
  background-color: #0056b3;
}

.tip-container {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #e9f5ff;
  border-radius: 8px;
  border-left: 4px solid #007bff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tip-text {
  color: #333;
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0;
  flex: 1;
  margin-right: 20px;
}

.tip-link {
  color: #007bff;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.tip-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.aspect-ratio-guide {
  width: 75px;
  height: 100px;
  border: 2px dashed #007bff;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.guide-box {
  width: 60px;
  height: 80px;
  background-color: rgba(0, 123, 255, 0.1);
}

.warning-message {
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.dropzone.has-warning {
  border-color: #ffc107;
}

.example-section {
  margin-top: 2rem;
}

.example-section h3 {
  text-align: center;
  margin-bottom: 1rem;
}

.example-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.example-item {
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.example-item h4 {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.example-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

@media (max-width: 768px) {
  .example-container {
    flex-direction: column;
  }

  .example-item {
    margin-bottom: 1rem;
  }
}
</style>