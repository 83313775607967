import { defineStore } from 'pinia'
import { ref } from 'vue'
import { supabase } from '../supabase'

export const useCreditsStore = defineStore('credits', () => {
  const credits = ref(0)

  const fetchCredits = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('user_credits')
        .select('credits')
        .eq('user_id', userId)
        .single()

      if (error) throw error

      credits.value = data.credits
    } catch (error) {
      console.error('Error fetching credits:', error.message)
    }
  }

  const deductCredits = async (userId, amount) => {
    try {
      const { data, error } = await supabase.rpc('deduct_credits', {
        p_user_id: userId,
        p_amount: amount
      })

      if (error) throw error

      credits.value = data
    } catch (error) {
      console.error('Error deducting credits:', error.message)
      throw error
    }
  }

  const resetCredits = () => {
    credits.value = 0
  }

  const updateCredits = (newCredits) => {
    credits.value = newCredits;
  }

  return {
    credits,
    fetchCredits,
    deductCredits,
    resetCredits,
    updateCredits
  }
})